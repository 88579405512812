import React, { Component } from "react";

import PropTypes from "prop-types";
import { Grid, withStyles, Button } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { showAlert } from "redux/actions";
import { getCookie } from "services/cookie";
import { connect } from "react-redux";

import style from "./style";
import ContactDetailsModal from "views/Common/ContactDetailsModal";

class ShortCuts extends Component {
  state = {
    showContact: false,
  };
  goToDelivery = () => {
    this.props.history.push("/settings?selected=delivery");
  };

  goToAddress = () => {
    this.props.history.push("/settings");
  };

  goToProduct = () => {
    this.props.history.push("/products");
  };

  goToCategory = () => {
    this.props.history.push("/categories");
  };

  getSharableLink = () => {
    const shopId = getCookie("shop_url");
    const link = `https://shopsapp.in/shops/${shopId}`;
    window.open(link);
  };

  copyContact = (type) => {
    let link = "hello@inventlabs.org";
    if (type === "phone") {
      link = "+917736043121";
    }
    let textField = document.createElement("textarea");
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // document.execCommand('copy', false, link)
    this.props.dispatch(
      showAlert(
        `Copied ${
          type === "phone" ? "Phone Number" : "Email Address"
        } Successfully`,
        { variant: "success" }
      )
    );
  };

  openContact = () => {
    this.setState({ showContact: true });
  };

  closeContact = () => {
    this.setState({ showContact: false });
  };

  gotoWhatsAppChat = () => {
    const link = `https://api.whatsapp.com/send/?phone=917736043121`;
    window.open(link);
  };

  render() {
    const { classes, status } = this.props;

    return (
      <React.Fragment>
        <Grid container item>
          <Grid
            item
            lg={3}
            md={4}
            xl={3}
            xs={6}
            className={classes.buttonContainer}
          >
            <Button
              className={classes.button}
              color="primary"
              size="large"
              variant="contained"
              onClick={this.getSharableLink}
              disableElevation
            >
              Open Shop
            </Button>
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            xl={3}
            xs={6}
            className={classes.buttonContainer}
          >
            <Button
              className={classes.button}
              color="primary"
              size="large"
              variant="contained"
              onClick={this.goToProduct}
              disableElevation
            >
              {status && !status.first_product
                ? "Add Products"
                : "Add Products"}
            </Button>
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            xl={3}
            xs={6}
            className={classes.buttonContainer}
          >
            <Button
              className={classes.button}
              color="primary"
              size="large"
              variant="contained"
              onClick={this.gotoWhatsAppChat}
              disableElevation
            >
              <WhatsAppIcon /> Contact Support
            </Button>
          </Grid>
        </Grid>
        <ContactDetailsModal
          isOpen={this.state.showContact}
          onCancel={this.closeContact}
          copyContact={this.copyContact}
        />
      </React.Fragment>
    );
  }
}

ShortCuts.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

export default compose(
  withStyles(style),
  withRouter
)(connect(mapStateToProps)(ShortCuts));
