import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import MarketingIcon from "../../../../assets/icons/MarketingIcon";
import CashIcon from "../../../../assets/icons/CashIcon";

const style = (theme) => ({
  container: {
    paddingTop: "150px",
    paddingBottom: "100px",
  },
  heading: {
    fontFamily: "Poppins, sans-serif",

    textAlign: "center",
    margin: "0 30px 10px",
  },
  subtitle: {
    fontFamily: "Poppins, sans-serif",

    fontWeight: 500,
    textAlign: "center",
    margin: "0px 20vw 0px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10vw 0px",
    },
  },
  boxContainer: {
    paddingTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80% !important",
  },
  box: {
    width: "auto",
    minWidth: "120px",
    height: "50px",
    boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
    borderRadius: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 250,
    minHeight: 75,
    display: "flex",
    alignItems: "center",
    borderRadius: "25px",
  },
  icon: {
    margin: "auto 10px auto",
  },
  boxText: {
    width: "150px",
    fontFamily: "Poppins, sans-serif",

    fontWeight: 550,
  },
});

class Features extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          className={classes.heading}
        >
          Why should you register on ShopsApp
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          gutterBottom
          className={classes.subtitle}
        >
          ShopsApp helps you create an online storefront.
        </Typography>
        <Grid container spacing={2} className={classes.boxContainer}>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <LocationOnIcon />
                </Grid>
                <Grid item>
                  <Grid item direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        Customizable Online Store & App
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <img
                    src="/images/home/inventory.png"
                    width="25px"
                    alt="inventory"
                  />
                </Grid>
                <Grid item>
                  <Grid item direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        Seamless Inventory Management
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <img
                    src="/images/home/import.png"
                    width="25px"
                    alt="import"
                  />
                </Grid>
                <Grid item>
                  <Grid item direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        Data Analytics & Insights
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container>
                <Grid item className={classes.icon}>
                  <CashIcon />
                </Grid>
                <Grid item>
                  <Grid item direction="column">
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        Affordable Plans for Every Business
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container>
                <Grid item className={classes.icon}>
                  <img
                    src="/images/home/food.png"
                    width="25px"
                    alt="delivery"
                  />
                </Grid>
                <Grid item>
                  <Grid item direction="column">
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        Integration with delivery agencies
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} md={4}>
            <Paper className={classes.paper} elevation={3}>
              <Grid container spacing={2}>
                <Grid item className={classes.icon}>
                  <MarketingIcon />
                </Grid>
                <Grid item>
                  <Grid item direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        variant="subtitle1"
                        className={classes.boxText}
                      >
                        No Technical Skills Required
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(style)(Features);
