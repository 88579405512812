import React, { Component } from "react";

import PropTypes from "prop-types";
import {
  Grid,
  withStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Dashboard as DashboardLayout } from "layouts";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { STATUS } from "redux/constants/index";

import { getOrderLocation } from "redux/actions";
import style from "./style";
import MapContainer from "./MapContainer";

// const orderStatusList = [{
//   id: 'confirmed',
//   value: 'Pending'
// }, {
//   id: 'shop_confirmed',
//   value: 'Confirmed'
// }, {
//   id: 'packed',
//   value: 'Packed'
// }, {
//   id: 'out_for_delivery',
//   value: 'Out for Delivery'
// }
// ]

class Locations extends Component {
  state = {
    searchString: "",
    page: 0,
    selectedShop: undefined,
    accept: "all",
    status: "all",
  };

  componentDidMount = () => {
    const { status, accept } = this.state;
    if (this.props.locations.status === STATUS.IDLE) {
      this.lastSearch = { status, accept };
      this.props.dispatch(getOrderLocation(status, accept));
    }
  };

  onSelectShop = (selectedShop) => {
    this.setState({ selectedShop });
  };

  gotoOrder = (id) => {
    this.props.history.push(`/orders/${id}`);
  };

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onClickSearch = () => {
    const { status, accept } = this.state;
    if (
      this.lastSearch.accept !== accept ||
      this.lastSearch.status !== status
    ) {
      this.props.dispatch(getOrderLocation(status, accept));
    }
  };

  getOrderPositions = () => {
    const { locations, classes } = this.props;
    const { selectedShop } = this.state;
    if (locations.status === STATUS.RUNNING && locations.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (locations.data.length === 0) {
      return (
        <Typography variant="h6">There are no active orders found</Typography>
      );
    }
    return (
      <React.Fragment>
        <MapContainer
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCXNvB8u-tKjRrAE52gHvWVqhBm5yDXd6k&v=3.exp&v=3.exp"
          // googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBujK5bEmXsfoAFjcA2rmF6PFBSoHziQ-I&v=3.exp&v=3.exp'
          loadingElement={<div style={{ height: "300px" }} />}
          containerElement={
            <div
              className={classes.mapContainer}
              style={{ height: "calc(100vh - 96px)", width: "100%" }}
            />
          }
          mapElement={<div style={{ height: "calc(100vh - 96px)" }} />}
          shops={locations.data}
          selectedShop={selectedShop}
          onSelectShop={this.onSelectShop}
          gotoOrder={this.gotoOrder}
        />
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    // const { status, accept } = this.props
    return (
      <DashboardLayout title="Delivery Locations">
        <div className={classes.root}>
          <Grid container className={classes.container} spacing={4}>
            {/* <div className={classes.filterContainer} >
              <Select placeholder='Shop Status' options={shopStatusList} valueParam='value' keyParam='id' selectedValue={status} handleChange={(event) => this.handleFieldChange('status', event.target.value)} />
              <Select placeholder='Accept Status' options={acceptStatusList} valueParam='value' keyParam='id' selectedValue={accept} handleChange={(event) => this.handleFieldChange('accept', event.target.value)} />
              <Button className={classes.searchButton} color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
                Search
              </Button>
            </div> */}
            {this.getOrderPositions()}
          </Grid>
        </div>
        {/* <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} /> */}
      </DashboardLayout>
    );
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    locations: state.orders.locations,
  };
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(Locations));
