import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// Externals
import PropTypes from "prop-types";
import compose from "recompose/compose";
import validate from "validate.js";
import _ from "underscore";
import { Grid, Typography, withStyles } from "@material-ui/core";

import validators from "common/validators";
import styles from "./style";
import schema from "./schema";
import addressSchema from "./addressSchema";
import shopSchema from "./shopSchema";
import { STATUS } from "redux/constants/index";
import { checkPasswordValidity } from "./../../services/utility";
import {
  UserDetails,
  ShopAddress,
  ShopDetails,
  VideoModal,
} from "./components";
import {
  signup,
  getShopCategories,
  getShopTypes,
  addShopDetails,
  addShopAddress,
  setAuthenticated,
  showAlert,
} from "redux/actions/index";
import treeChanges from "tree-changes";
import ShopsAppLogo from "../../assets/icons/ShopsAppLogo";

validate.validators.checked = validators.checked;

class SignUp extends Component {
  state = {
    stage: 1,
    values: {
      firstName: "",
      phoneNumber: "",
      email: "",
      password: "",
      referCode: "",
    },
    shop: {
      customerCare: "",
      shopName: "",
      type: "",
      category: [],
    },
    address: {
      latlng: {},
      level1: "",
      level2: "",
    },
    touched: {
      firstName: false,
      phoneNumber: false,
      email: false,
      password: false,
      customerCare: null,
      shopName: null,
      category: null,
    },
    errors: {
      firstName: null,
      phoneNumber: null,
      email: null,
      password: null,
      customerCare: null,
      shopName: null,
      category: null,
      level1: null,
      level2: null,
    },
    isValid: false,
    isShopValid: false,
    isShopAddressValid: false,
    isLoading: false,
    isShopLoading: false,
    isShopAddressLoading: false,
    submitError: null,
    submitShopError: null,
    submitShopAddressError: null,
    openVideoModal: false,
  };

  componentDidMount = () => {
    const { dispatch, location, history } = this.props;
    dispatch(getShopCategories());
    dispatch(getShopTypes());
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("code");
    if (referralCode) {
      this.setState({ ...this.state, values: { referCode: referralCode } });
    }
    if (
      history?.location?.state?.isRedirected &&
      history?.location?.state?.stage === 2
    ) {
      this.setState({ isLoading: false });
      this.setState({ stage: 2 });
      let state = { ...history.location.state };
      delete state.isRedirected;
      history.replace({ ...history.location, state });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { dispatch, history } = this.props;
    console.log(history);
    const { changedTo } = treeChanges(this.props, nextProps);
    if (
      changedTo("user.signupStatus", STATUS.READY) &&
      nextProps.user.userInfo.name &&
      !nextProps.user.userInfo.shop
    ) {
      this.setState({ isLoading: false });
      this.setState({ stage: 2 });
      if (window.location.pathname === "/home") {
        history.push({
          pathname: "/sign-up",
          state: { stage: 2, isRedirected: true },
        });
      }
    } else if (changedTo("user.signupStatus", STATUS.ERROR)) {
      dispatch(showAlert(nextProps.user.message, { variant: "error" }));
      this.setState({ isLoading: false });
    }
    if (changedTo("shopDetails.status", STATUS.READY)) {
      this.setState({ isShopLoading: false });
      this.setState({ stage: 3 });
    } else if (changedTo("shopDetails.status", STATUS.ERROR)) {
      dispatch(showAlert(nextProps.shopDetails.message, { variant: "error" }));
      this.setState({ isShopLoading: false });
    }
    if (changedTo("shopAddress.status", STATUS.READY)) {
      this.setState({ isShopAddressLoading: false });
      dispatch(setAuthenticated());
    } else if (changedTo("shopAddress.status", STATUS.ERROR)) {
      dispatch(showAlert(nextProps.shopAddress.message, { variant: "error" }));
      this.setState({ isShopAddressLoading: false });
    }
    console.log(nextProps);
  };

  validateUserForm = _.debounce(() => {
    const { values } = this.state;

    const newState = { ...this.state };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = !errors;
    this.setState(newState);
  }, 30);

  validateShopForm = _.debounce(() => {
    const { shop } = this.state;

    const newState = { ...this.state };
    const errors = validate(shop, shopSchema);
    newState.errors = errors || {};
    newState.isShopValid = !errors;
    this.setState(newState);
  }, 30);

  validateShopAddressForm = _.debounce(() => {
    const { address } = this.state;

    const newState = { ...this.state };
    const errors = validate(address, addressSchema);
    newState.errors = errors || {};
    newState.isShopAddressValid = !errors;
    this.setState(newState);
  }, 30);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state };

    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;

    this.setState(newState, this.validateUserForm);
  };

  handleShopFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.submitShopError = null;
    newState.touched[field] = true;
    newState.shop[field] = value;

    this.setState(newState, this.validateShopForm);
  };

  handleShopAddressFieldChange = (field, value) => {
    const newState = { ...this.state };
    newState.submitShopAddressError = null;
    newState.touched[field] = true;
    newState.address[field] = value;

    this.setState(newState, this.validateShopAddressForm);
  };

  submitUserDetails = () => {
    const { dispatch } = this.props;
    const { values } = this.state;
    const isValidPassword = checkPasswordValidity(values.password);
    if (!isValidPassword) {
      const warning =
        "Password must contain at least six characters, including uppercase and lowercase letters and numbers.";
      dispatch(showAlert(warning, { variant: "error" }));
      return;
    }
    const request = {
      phone: 91 + values.phoneNumber,
      email: values.email,
      password: values.password,
      name: values.firstName,
      invite_code: values.referCode,
    };
    this.setState({ isLoading: true });
    dispatch(signup(request));
  };

  submitShopDetails = () => {
    const { dispatch, user } = this.props;
    const { shop } = this.state;
    const request = {
      name: shop.shopName,
      email: user.requestedUser.email,
      phone: shop.phone || shop.customerCare,
      customer_care: shop.customerCare,
      shop_type: shop.type,
      base_categories: shop.category,
    };
    dispatch(addShopDetails(request));
    this.setState({ isShopLoading: true });
  };

  submitShopAddress = (request) => {
    const { dispatch } = this.props;
    dispatch(addShopAddress(request, true));
    this.setState({ isShopAddressLoading: true, isLoading: true });
  };

  handleVideoModalClose = () => {
    this.setState({ openVideoModal: false });
  };

  handleVideoModalOpen = () => {
    const android = navigator.userAgent.match(/Android/i);
    const ios = navigator.userAgent.match(/iPhone|iPod/i);
    if (android || ios) {
      window.open("https://www.youtube.com/embed/n2WMhIijiqE?rel=0");
    } else {
      this.setState({ openVideoModal: true });
    }
  };

  render() {
    const { classes, location } = this.props;
    const {
      shop,
      stage,
      values,
      address,
      touched,
      errors,
      isValid,
      isShopAddressValid,
      isShopValid,
      submitError,
      submitShopError,
      submitShopAddressError,
      isLoading,
      isShopLoading,
      isShopAddressLoading,
      openVideoModal,
    } = this.state;

    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("code");

    const showFirstNameError =
      touched.firstName && errors.firstName ? errors.firstName[0] : false;
    const showLastNameError =
      touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber[0] : false;
    const showEmailError =
      touched.email && errors.email ? errors.email[0] : false;
    const showPasswordError =
      touched.password && errors.password ? errors.password[0] : false;
    // const showPolicyError =
    //   touched.policy && errors.policy ? errors.policy[0] : false
    const showCategoryError =
      touched.category && errors.category ? errors.category[0] : false;
    const showPhoneError =
      touched.phone && errors.phone ? errors.phone[0] : false;
    const showCustomerCareError =
      touched.customerCare && errors.customerCare
        ? errors.customerCare[0]
        : false;
    const showShopNameError =
      touched.shopName && errors.shopName ? errors.shopName[0] : false;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid className={classes.quoteWrapper} lg={6} container>
              <Grid xs={12}>
                <div className={classes.logo}>
                  <ShopsAppLogo />
                  <Typography className={classes.logoTitle} variant="h3">
                    ShopsApp
                  </Typography>
                </div>
              </Grid>
              <Grid xs={12} className={classes.quoteContainer} container>
                <Grid xs={6}>
                  <Typography className={classes.quoteText}>
                    Create an online storefront and start selling now
                  </Typography>
                  <div className={classes.underLine} />
                </Grid>
                <Grid xs={6}>
                  <img
                    src="/images/arrow-icon.png"
                    alt="arrow-icon"
                    className={classes.arrowIcon}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                className={classes.playVideoContainer}
                onClick={this.handleVideoModalOpen}
              >
                <img
                  src="/images/play-icon.png"
                  alt="play-icon"
                  className={classes.playIcon}
                />
                <Typography className={classes.playText}>
                  Watch Video
                </Typography>
              </Grid>
              <Grid xs={12} className={classes.sponserContainer}>
                <img
                  src="/images/kerala-police.png"
                  alt="keralapolice-icon"
                  width="75px"
                />
                <img
                  src="/images/horticorp.png"
                  alt="horticorp-icon"
                  width="85px"
                  className={classes.sponserLogo}
                />
                <img
                  src="/images/matsyafed.png"
                  alt="matsyafed-icon"
                  width="85px"
                  className={classes.sponserLogo}
                />
                <img
                  src="/images/supplyco.jpg"
                  alt="supplyco-icon"
                  width="75px"
                  className={classes.sponserLogo}
                />
              </Grid>
            </Grid>
            <Grid className={classes.content} item lg={6} xs={12}>
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  {stage === 1 ? (
                    <UserDetails
                      values={values}
                      isValid={isValid}
                      errors={errors}
                      submitUserDetails={this.submitUserDetails}
                      handleFieldChange={this.handleFieldChange}
                      showFirstNameError={showFirstNameError}
                      showLastNameError={showLastNameError}
                      showEmailError={showEmailError}
                      showPasswordError={showPasswordError}
                      isLoading={isLoading}
                      submitError={submitError}
                      referCodeDisable={referralCode}
                    />
                  ) : null}
                  {stage === 2 ? (
                    <ShopDetails
                      values={shop}
                      type="signup"
                      isValid={isShopValid}
                      errors={errors}
                      submitShopDetails={this.submitShopDetails}
                      handleFieldChange={this.handleShopFieldChange}
                      isLoading={isShopLoading}
                      submitError={submitShopError}
                      showShopNameError={showShopNameError}
                      showPhoneError={showPhoneError}
                      showCustomerCareError={showCustomerCareError}
                      showCategoryError={showCategoryError}
                    />
                  ) : null}
                  {stage === 3 ? (
                    <ShopAddress
                      values={address}
                      type="signup"
                      isValid={isShopAddressValid}
                      errors={errors}
                      submitShopAddressDetails={this.submitShopAddress}
                      handleFieldChange={this.handleShopAddressFieldChange}
                      isLoading={isShopAddressLoading || isLoading}
                      submitError={submitShopAddressError}
                      showCategoryError={showCategoryError}
                    />
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
          <VideoModal
            handleClose={this.handleVideoModalClose}
            open={openVideoModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    shopDetails: state.address.shopDetails,
    shopAddress: state.address.shopAddress,
  };
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(SignUp));
